<template>
  <div id="robot-list">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus-circle"
          @click="onCreateClick"
          >{{ $l.get("dictionary.create-button") }}</vs-button
        >
      </div>
      <vs-table
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        v-if="data"
        :data="data.robotsPage.results"
      >
        <template slot="header">
          <h3>{{ $l.get("robot-list.table-header") }}</h3>
        </template>
        <template slot="thead">
          <vs-th sort-key="name">{{ $l.get("robot-list.table-name") }}</vs-th>
          <vs-th>{{ $l.get("dictionary.view") }}</vs-th>
          <vs-th>{{ $l.get("dictionary.edit") }}</vs-th>
          <vs-th>{{ $l.get("dictionary.duplicate") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="assessment"
                @click="onRobotViewClick(tr)"
              ></vs-button>
            </vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="edit"
                @click="onRobotEditClick(tr)"
              ></vs-button>
            </vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-copy"
                @click="onRobotDuplicateClick(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="margin-top: 20px; text-align: center">
        <vs-pagination
          :total="Math.ceil(data.robotsPage.count / 25)"
          v-model="page"
          v-if="data"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SelectRobotDuplicateInteractor from "@/business/robot-list/select-robot-duplicate";
import SelectRobotViewInteractor from "@/business/robot-list/select-robot-view";
import SelectCreateInteractor from "@/business/robot-list/select-create";
import SelectRobotEditInteractor from "@/business/robot-list/select-robot-edit";
import ChangeFilterInteractor from "@/business/robot-list/change-filter";
import InitRobotListInteractor from "@/business/robot-list/init-robot-list";
import RobotListScreenController from "@/adapters/controllers/screen-robot-list";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "robot-list",
  data() {
    return {
      controller: null,
      interactors: {
        selectRobotDuplicate: null,
        selectRobotView: null,
        selectCreate: null,
        selectRobotEdit: null,
        changeFilter: null,
        initRobotListScreen: null
      },
      data: null,
      isLoading: false,
      page: 0,
      search: "",
      sort: "",
      searchTimer: null
    };
  },
  components: { Loader },
  beforeMount() {
    this.controller = this.$injector.get(RobotListScreenController);

    //{ INTERACTORS
    this.interactors.initRobotListScreen = this.$injector.get(
      InitRobotListInteractor
    );
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.selectRobotEdit = this.$injector.get(
      SelectRobotEditInteractor
    );
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    this.interactors.selectRobotView = this.$injector.get(
      SelectRobotViewInteractor
    );
    this.interactors.selectRobotDuplicate = this.$injector.get(
      SelectRobotDuplicateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initRobotListScreen.handle();
  },
  watch: {
    page: function() {
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    handleSearch(searching) {
      this.search = searching;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      const _ = this;
      this.searchTimer = setTimeout(() => {
        _.searchTimer = null;
        if (_.page != 1) {
          _.page = 1; // watch force update
        } else {
          _.interactors.changeFilter.handle(_.page, _.search, _.sort);
        }
        //
      }, 500);
    },
    handleSort(key, active) {
      if (active) {
        const prefix = active == "desc" ? "-" : "";
        this.sort = `${prefix}${key}`;
      } else {
        this.sort = "";
      }
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    },
    onRobotViewClick(data) {
      this.interactors.selectRobotView.handle(data);
    },
    onRobotEditClick(data) {
      this.interactors.selectRobotEdit.handle(data);
    },
    onRobotDuplicateClick(data) {
      this.interactors.selectRobotDuplicate.handle(data);
    },
    onCreateClick() {
      this.interactors.selectCreate.handle();
    }
  }
};
</script>

<style lang="scss"></style>
